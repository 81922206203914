<template>
	<Fragment>
		<!--		<div class="body-contents" style="padding-top: 80px">-->
		<!--			<div class="container">-->
		<!--				<div class="section-wrap gap64">-->
		<!--					<div class="section border-bottom">-->
		<!--						<div class="contents text-center">-->
		<!--							&lt;!&ndash; 신규 주문과 문구 다름  &ndash;&gt;-->
		<!--							<p class="text-large">다우오피스 멤버십 결제가 완료되었습니다.</p>-->
		<!--							<img class="guide" src="@/assets/img/dada.png" />-->
		<!--							<p class="text-medium mb-16">-->
		<!--								구매하신 서비스 및 상품이 적용되기까지 <em class="primary">약 10분~20분</em> 정도 소요됩니다.<br />-->
		<!--								구매 상품이 적용되지 않았을 경우 스피카 챗봇 상담 혹은<br />-->
		<!--								영업담당자에게 연락 주시기 바랍니다.<br />-->
		<!--							</p>-->
		<!--							<p class="description">-->
		<!--								상담문의 : 1577-3019<br />-->
		<!--								상담시간 : 09:00 ~ 17:00 (점심 12:00~13:00)<br />-->
		<!--								주말 / 공휴일 휴무<br />-->
		<!--							</p>-->
		<!--						</div>-->
		<!--					</div>-->
		<!--				</div>-->
		<!--			</div>-->
		<!--		</div>-->
	</Fragment>
</template>

<script>
export default {
	data() {
		return {
			paymentUrl: '/kiwoom/popup',
		};
	},
	methods: {},
	created() {},
	mounted() {
		const success = this.$route.query.success;
		const close = this.$route.query.close;
		if (close) {
			if (success) {
				window.opener.postMessage('success', location.origin);
			} else {
				window.opener.postMessage('close', location.origin);
			}
		}

		setTimeout(() => {
			window.close();
		}, 1000);
	},
};
</script>

<style scoped>
@import '../../assets/css/common.css';
</style>
